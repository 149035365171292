import React from 'react';
import FooterBackground from '../../images/footer-pattern-sm.png';
import FooterStyle from './footer-style'

export default function Footer() {
  return (
    <>
      <FooterStyle style={{ backgroundImage: `url(${FooterBackground})` }}>
        <div className="footer-inner">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-6 footerInfo">
                <div className="address">
                  <a href='https://goo.gl/maps/nmHLVPwQcvXpLk8p6' target='_blank' rel='noreferrer' aria-label='Go to Google Maps' >
                    100 Kinloch Lane <br />
                    Manakin Sabot, VA 23103
                  </a>
                </div>
                <div className="phone">
                  <a href='tel:8047848000' rel='noreferrer' aria-label='Call 804.784.8000'>
                    804.784.8000
                  </a>
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="copyright">
                  &copy; {new Date().getFullYear()} Kinloch Golf Club. All Rights Reserved.
                </div>
              </div>
            </div>
          </div>
        </div> 
      </FooterStyle>
    </>
  );
}
