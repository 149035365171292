import styled from 'styled-components'

const FooterStyle = styled.div`
  
  background-color: rgb(32, 47, 102);
  padding: 3rem 0;

  .logoStyle {
    width: auto;
    height: 84px;
  }

  .leftSection {
    display: flex;
    .right {
      padding-left: 0.3rem;
    }
  }

  .rightSection {
    display: flex;
    float: right;
    .left {
      padding-right: 0.5rem;
      text-align: right;
    }
  }

  .footer-inner {
    margin: 0 auto;
    display: inline-block;
    width: 100%;
    height: 100%;
    color: white;
    vertical-align: bottom;
    line-height: 1.5rem;
    font-family: 'OpenSansRegular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-family: var(--OpenSansRegular);
  }

  .footerInfo {

    .address {
      a {
        font-family: 'OpenSansBold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-family: var(--OpenSansBold);
        font-size: 1.0rem;
        color: #FFFFFF;
        text-decoration: none;
      }
      a:hover {
        text-decoration: underline;
      }
    }

    .phone {
      padding: .5rem 0;
      a {
        font-family: 'OpenSansRegular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-family: var(--OpenSansRegular);
        font-weight: 300;
        font-size: 1.0rem;
        color: #FFFFFF;
        text-decoration: none;
      }
      a:hover {
        text-decoration: underline;
      }
    }

    .email {
      a {
        font-family: 'OpenSansRegular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-family: var(--OpenSansRegular);
        font-weight: 300;
        font-size: 1.0rem;
        color: #FFFFFF;
        text-decoration: none;
      }
      a:hover {
        text-decoration: underline;
      }
    }
  }

  .copyright {
    font-family: 'OpenSansRegular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-family: var(--OpenSansRegular);
    font-weight: 300;
    font-size: 1.0rem;
    color: #FFFFFF;
    position: absolute;
    bottom: 0;
    right: 15px;
  }

  /* ----- @media 575.98 ----- */
  @media (max-width: 575.98px) { 
    .copyright {
      font-family: 'OpenSansRegular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-family: var(--OpenSansRegular);
      font-weight: 300;
      color: #FFFFFF;
      position: relative;
      margin-top: 2rem;
      right: 0;
    }
  }
  
`;

export default FooterStyle;