import React, { useState, useEffect} from "react";
import { Link } from 'gatsby';
import logo from '../../images/kinloch-logo.svg';
import bannerLogo from '../../images/2024 U.S. MID-AMATEUR_FULL COLOR LOGO.png';
import Nav from '../nav/nav';
import HeaderStyle from './header-style';

export default function Header() {  
  const [header, setHeader] = useState('header')

  const listenScrollEvent = (event) => {
    if (window.scrollY < 73) {
      return setHeader('header')
    } else if (window.scrollY > 70) {
      return setHeader('headeralt')
    } 
  }
  
  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);
  
    return () =>
      window.removeEventListener('scroll', listenScrollEvent);
  }, []);

  return (
    <>
      <HeaderStyle>
        <div className="banner">
          <div className="logo-nav main mid-am container">
            <div className="logo-container main mid-am">
              <Link to="/2024-mid-am" aria-label='Go to 2024 Mid Am'>
                <img className="logoStyle main mid-am" src={bannerLogo} alt="Kinloch 2024 Mid Am" />
              </Link>
              <Link to="/2024-mid-am" aria-label='Go to 2024 Mid Am' className="cta_link">
                <div className="cta">Click Here for information on the 2024 U.S. Mid-Amateur Championship.</div>
              </Link>
            </div>
          </div>
        </div>
        <div className={header}>
          <div className="logo-nav container">
            <div className="logo-container">
              <Link to="/" aria-label='Go to guest home'>
                <img className="logoStyle" src={logo} alt="Kinloch" />
              </Link>
            </div>
            <Nav />
          </div>
        </div>
      </HeaderStyle>
    </>
  );
} 