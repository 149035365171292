import styled from 'styled-components'

const HeaderStyle = styled.div`
  
  .logoStyle {
    width: 8.125rem;
    padding-top: 1.25rem;
    &.mid-am {
      width: 12.5rem;
      padding-top: 0;
      border: 5px solid #c39200;
      border-radius: 120px;
      background-color: white;
      &.main {
        width: 6.25rem;
      }
    }
    &.mid-am-info {
      width: 12.5rem;
      padding-top: 0;
      background-color: white;
      &.main {
        width: 6.25rem;
      }
    }
  }

  /* ----- @media 991.98px ----- */
  @media (max-width: 991.98px) { 
    .logoStyle {
      width: 6.25rem;
      padding-top: 0rem;
      margin-top: -0.75rem;
    }
  }

.banner {
  margin-top: 0;
  position: fixed;
  width: 100%;
  z-index: 100;
}

.header {
  display: flex;
  background-color: rgba(18, 40, 76, 0.67);
  justify-content: space-between;
  align-items: center;
  padding: 0 2.5rem;
  height: 5rem;
  position: fixed;
  width: 100%;
  z-index: 1;
  top: 0;
  margin-top: 92px;
  &.mid-am {
    /* height: auto;
    padding-top: 30px;
    background-color: rgba(18, 40, 76); */
    background-color: transparent;
    background-image: none !important;
  }
  &.mid-am-info {
    margin-top: 4rem;
    background-color: transparent;
    background-image: none !important;
    position: absolute;
  }
}

.headeralt {
  display: flex;
  width: 100%;
  background-color: rgb(18, 40, 76);
  justify-content: space-between;
  align-items: center;
  padding: 0 2.5rem;
  height: 5rem;
  position: fixed;
  top: 0;
  z-index: 1;
  animation: myanimation .5s;
  &.mid-am {
    height: auto;
    padding-top: 30px;
  }
}

@keyframes myanimation {
  0% {
    background-color: rgba(18, 40, 76, 0.67);
    color: transparent;
  }
  100% {
    background-color: rgb(18, 40, 76);
    color: rosybrown;
  }
}

.logo-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0!important;
  &.mid-am {
    padding-bottom: 15px !important;
    position: relative;
    &.main {
      padding-bottom: 0 !important;
      max-width: 100%;
    }
    &::before {
      content: "";
      position: absolute;
      top: 32%;
      left: 0;
      width: 100%;
      height: 68%;
      background-color: rgba(18, 40, 76);
    }
    &.main::before {
      top: 0;
      height: 100%;
    }
  }
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6.875rem;
  z-index: 9;
  &.mid-am {
    margin-top: 3.875rem;
    &.main {
      margin: 3px auto;
    }
  }
  /* &.mid-am-info {
    margin-top: 13.875rem;
  } */
}

@media (max-width: 575.98px) {
  .logo-container {
    margin-top: 5.875rem;
    padding-left: 1rem;
  }

  .header.mid-am, .headeralt.mid-am {
      padding-right: 0;
    }
}

/* ----- @media 768 ----- */
@media (max-width: 767.98px) {
  .header {
    background-color: rgb(18, 40, 76);
    height: 3.75rem;
    padding: 0 0.838rem 0 0;
    z-index: 1;
  }
  .headeralt {
    height: 3.75rem;
    padding: 0 0.838rem 0 0;
    z-index: 1;
  }
  .logo-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 0;
    padding-left: 0;
    &.mid-am {
      display: grid;
    }
  }
  .logoStyle {
    width: 7.25rem;
    padding-top: 1rem;
  }
}

`;

export default HeaderStyle;